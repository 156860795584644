.about {
    &__container {
        width: 60%;
        display: flex;
        justify-content: center;
        height: 50vh;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
    &__text {
        color: #adadad;
        width: 60%;
        font-size: 16px;
    }
}

@media (min-width: 767px) {
    .about {
        &__text {
            font-size: 20px;
        }
    }
}