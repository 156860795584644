.footer {
    left: 0;
    bottom: 0;
    width: 50%;
    text-align: center;
    position: fixed;
    p {
        color: #434f4e;
        font-weight: 700;
    }
}