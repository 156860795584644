.header {
    &__container {
        height: 200px;
    }
    &__date {
        display: flex;
        justify-content: center;
        cursor: default;
    }
    &__tag {
        display: flex;
        margin-left: 5%;
        h1 {
            background-color: #f5035f;
            color: #fff;
            width: 40px;
            height: 40px;
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            border-radius: 4px;
        }
        &--yellow {
            h1 {
                background-color: #fdcb00;
            }
        }
        &--purple {
            h1 {
                background-color: #00ebea;
            }
        }
    }
}